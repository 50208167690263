<template>
  <div class="apply-container">
    <div class="title">入驻申请</div>
    <div class="type-box">
      <div class="wec">欢迎您加入《为它》商户平台</div>
      <div class="choice">选择您经营的店铺类型</div>
      <div>
        <el-radio-group v-model="radio">
          <el-radio :label="1">线上网店</el-radio>
          <el-radio :label="2">线下宠物店</el-radio>
          <el-radio :label="3">猫舍</el-radio>
          <el-radio :label="4">犬舍</el-radio>
          <el-radio :label="5">宠物医院</el-radio>
          <el-radio :label="6">宠物微商</el-radio>
        </el-radio-group>
      </div>
      <div class="next">
        <el-button size="small" @click="nextPage()"  type="primary">下一步</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  name: "apply",
  data() {
    return {
      radio: 1
    };
  },
  methods: {
    nextPage() {
      var radio = this.radio

      this.$router.push({
        path:"/info",
        query: {radio}
      })
    }
  },
};
</script>

<style lang="less" scoped>
.apply-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    .title {
      margin-bottom: 40px;
      width: 200px;
      text-align: center;
      color: #fff;
      font-weight: 700;
      padding: 5px 20px;
      background-color: rgb(245, 154, 35);
    } 
    .type-box {
      width: 50%;
      padding: 20px;
      border: 1px dashed #222;
      .choice {
        margin-top: 30px;
        font-weight: 700;
      }
      .el-radio-group {
        display: flex;
        flex-direction: column;
        padding-left:200px ;
        .el-radio {
          margin-top: 30px;
          .el-input {
            width: 200px;
          }
        }
      }
      .wec {
        text-align: center;
        color: rgb(217, 0, 27);
        font-weight: 700;
        font-size: 16px;
      }
      .next {
        display: flex;
        justify-content: flex-end;
        .el-button {
          padding: 10px 30px;
        }
      }
    }
}
</style>
